var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _vm.isShowFn == 2
      ? _c("div", { staticClass: "logo-case-wrap2" }, [
          _c(
            "a",
            { staticClass: "logo-case", attrs: { href: "/messagelist/mini" } },
            [
              _c("img", { attrs: { src: _vm.platform.platformLogo, alt: "" } }),
              _vm._v(" "),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.platform.platformName) }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isShowFn == 1
      ? _c("div", { staticClass: "logo-case-wrap" }, [
          _c("div", { staticClass: "logo-case-img" }, [
            _c("img", {
              staticClass: "logo-case-img",
              attrs: { src: _vm.platform.platformLogo, alt: "" },
              on: {
                mouseenter: function($event) {
                  _vm.isBackShow = true
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBackShow,
                    expression: "isBackShow"
                  }
                ],
                staticClass: "backBox",
                on: {
                  mouseleave: function($event) {
                    _vm.isBackShow = false
                  }
                }
              },
              [
                _c("div", { staticClass: "triangle" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "back-title",
                  domProps: { innerHTML: _vm._s(_vm.appName) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "back-list-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "back-list-item",
                      on: {
                        click: function($event) {
                          return _vm.$router.replace("/messagelist/mini")
                        }
                      }
                    },
                    [
                      _c("p", [_vm._v("返回首页")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "el-icon-arrow-right" })
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isShowFn == 3
      ? _c("div", { staticClass: "logo-case-title" }, [_vm._v("志愿服务")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.versionsIcon != -1
          ? _c(
              "el-dropdown",
              { on: { command: _vm.handleCommandStatistics } },
              [
                _c("div", { staticClass: "statistics-logo" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/common/statistics.png"),
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v("平台数据")])
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  _vm._l(_vm.statList, function(item, index) {
                    return _c(
                      "el-dropdown-item",
                      { key: "s" + index, attrs: { command: item.id } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowFn != 3 && _vm.volunteerRole()
          ? _c(
              "div",
              {
                staticClass: "top-volunteer-case",
                on: { click: _vm.handleClickOpenVolunteer }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/common/volunteer.png"),
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("志愿服务")])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        [
          _vm.versionsIcon != -1
            ? _c(
                "div",
                { staticClass: "top-icon-case" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "版本管理",
                        effect: "dark",
                        placement: "bottom"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "top-ico icon iconfont iconbanben",
                        attrs: { "icon-class": "bell" },
                        on: { click: _vm.toVersion }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "top-icon-case",
            attrs: { width: "124px" },
            on: {
              click: function($event) {
                return _vm.jumpCustomerService()
              }
            }
          },
          [
            _c(
              "i",
              {
                staticClass: "top-ico icon iconfont iconkefubeifen",
                attrs: { "icon-class": "bell" }
              },
              [
                _vm.cServerNum > 0
                  ? _c("div", { staticClass: "alert-nums" }, [
                      _vm._v(_vm._s(_vm.cServerNum))
                    ])
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            on: { command: _vm.handleClickGotoNotice }
          },
          [
            _c(
              "div",
              { staticClass: "top-icon-case", attrs: { width: "124px" } },
              [
                _c(
                  "i",
                  {
                    staticClass: "top-ico icon iconfont iconxiaoxibeifen",
                    attrs: { "icon-class": "bell" }
                  },
                  [
                    _vm.informNum > 0
                      ? _c("div", { staticClass: "alert-nums" }, [
                          _vm._v(_vm._s(_vm.informNum))
                        ])
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              {
                attrs: { slot: "dropdown" },
                on: { click: _vm.handleClickGotoNotice },
                slot: "dropdown"
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { command: "0", divided: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleClickGotoNotice("0")
                      }
                    }
                  },
                  [
                    _vm.readNum > 0
                      ? _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: _vm.readNum }
                          },
                          [_vm._v("通知中心")]
                        )
                      : _c("span", [_vm._v("通知中心")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { command: "1", divided: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleClickGotoNotice("1")
                      }
                    }
                  },
                  [
                    _vm.backlogNum > 0
                      ? _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: _vm.backlogNum }
                          },
                          [_vm._v("待办事项")]
                        )
                      : _c("span", [_vm._v("待办事项")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { command: "2", divided: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleClickGotoNotice("2")
                      }
                    }
                  },
                  [
                    _vm.notifyNoticeCount > 0
                      ? _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: _vm.notifyNoticeCount }
                          },
                          [_vm._v("系统消息")]
                        )
                      : _c("span", [_vm._v("系统消息")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { command: "3", divided: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleClickGotoNotice("3")
                      }
                    }
                  },
                  [
                    _vm.weappTodoNum > 0
                      ? _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: _vm.weappTodoNum }
                          },
                          [_vm._v("商品表单通知")]
                        )
                      : _c("span", [_vm._v("商品表单通知")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "hover", placement: "bottom" },
            on: { command: _vm.handleClickGoto }
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: {
                  src: _vm.user.avatar
                    ? _vm.user.avatar +
                      "?imageMogr2/auto-orient/strip|imageView2/1/w/80/h/80"
                    : _vm.$IMG_DOMAIN + "/wxms-avatar.png"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "avatar-wrapper-name" }, [
                _vm._v(_vm._s(_vm.user.nickname))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "el-icon-arrow-right" })
            ]),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "span",
                  { staticStyle: { display: "block" } },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "c", divided: "" } },
                      [_vm._v("\n            账号设置\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: { display: "block" },
                    attrs: { command: "b" },
                    on: { click: _vm.handleClickLoginOut }
                  },
                  [
                    _c("el-dropdown-item", { attrs: { divided: "" } }, [
                      _vm._v("\n            退出登录\n          ")
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "help-box", on: { click: _vm.onOffHelpDownList } },
          [
            _c("span", { staticClass: "icon iconfont iconbangzhu1" }),
            _vm._v(" "),
            _c("span", { staticClass: "help-box-title" }, [_vm._v("帮助")]),
            _vm._v(" "),
            _vm.showHelpDownList == true
              ? _c("div", { staticClass: "help-down-list-case" }, [
                  _c(
                    "div",
                    {
                      staticClass: "help-down-item",
                      on: { click: _vm.goToHelp }
                    },
                    [_vm._v("帮助中心")]
                  ),
                  _vm._v(" "),
                  _vm.platform.platformCustomerServiceOpen == 1
                    ? _c("div", { staticClass: "help-down-item contact-us" }, [
                        _vm._v(
                          _vm._s(
                            _vm.platform.platformCustomerServiceButtonName
                          ) + "\n          "
                        ),
                        _c("div", { staticClass: "contact-us-info" }, [
                          _vm.platform.platformCustomerServiceQrcode
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.platform.platformCustomerServiceQrcode
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "contact-us-description" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.platform.platformCustomerServiceName
                                    ? _vm.platform.platformCustomerServiceName
                                    : "开问网微信客服"
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "contact-us-phone" }, [
                            _c("i", {
                              staticClass: "icon iconfont icon-lianxiwomen"
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.platform.platformCustomerServicePhone
                                    ? _vm.platform.platformCustomerServicePhone
                                    : "010-53399446"
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }