var getters = {
  deployUploadApi: function deployUploadApi(state) {
    return state.api.deployUploadApi;
  },
  databaseUploadApi: function databaseUploadApi(state) {
    return state.api.databaseUploadApi;
  },
  size: function size(state) {
    return state.app.size;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  user: function user(state) {
    return state.user.user;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  socketApi: function socketApi(state) {
    return state.api.socketApi;
  },
  imagesUploadApi: function imagesUploadApi(state) {
    return state.api.imagesUploadApi;
  },
  baseApi: function baseApi(state) {
    return state.api.baseApi;
  },
  fileUploadApi: function fileUploadApi(state) {
    return state.api.fileUploadApi;
  },
  updateAvatarApi: function updateAvatarApi(state) {
    return state.api.updateAvatarApi;
  },
  qiNiuUploadApi: function qiNiuUploadApi(state) {
    return state.api.qiNiuUploadApi;
  },
  sqlApi: function sqlApi(state) {
    return state.api.sqlApi;
  },
  swaggerApi: function swaggerApi(state) {
    return state.api.swaggerApi;
  },
  miniMessage: function miniMessage(state) {
    return state.mini.miniMessage;
  },
  applyMessage: function applyMessage(state) {
    return state.mini.applyMessage;
  },
  allClass: function allClass(state) {
    return state.mini.allClass;
  },
  currentIndex: function currentIndex(state) {
    return state.desiner.currentIndex;
  },
  contentIndex: function contentIndex(state) {
    return state.desiner.contentIndex;
  },
  edit_id: function edit_id(state) {
    return state.desiner.edit_id;
  },
  textFrameShow: function textFrameShow(state) {
    return state.desiner.textFrameShow;
  },
  insterImgShow: function insterImgShow(state) {
    return state.desiner.insterImgShow;
  },
  isShowNotice: function isShowNotice(state) {
    return state.desiner.isShowNotice;
  },
  isShowImgText: function isShowImgText(state) {
    return state.desiner.isShowImgText;
  },
  isShowMap: function isShowMap(state) {
    return state.desiner.isShowMap;
  },
  isShowtitleHead: function isShowtitleHead(state) {
    return state.desiner.isShowtitleHead;
  },
  nav_idx: function nav_idx(state) {
    return state.desiner.nav_idx;
  },
  searchBarOpen: function searchBarOpen(state) {
    return state.desiner.searchBarOpen;
  },
  skinObj: function skinObj(state) {
    return state.desiner.skinObj;
  },
  createShow: function createShow(state) {
    return state.desiner.createShow;
  },
  isShowContent: function isShowContent(state) {
    return state.desiner.isShowContent;
  },
  isShowModule: function isShowModule(state) {
    return state.desiner.isShowModule;
  },
  isShowVideoLive: function isShowVideoLive(state) {
    return state.desiner.isShowVideoLive;
  },
  videoLive: function videoLive(state) {
    return state.desiner.videoLive;
  }
};
export default getters;