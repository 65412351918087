import request from '@/utils/request'; //快速创建小程序

export function createMini(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/fast-register',
    method: 'post',
    data: data
  });
} //快速创建小程序

export function createTrialMini(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/beta-verify',
    method: 'post',
    data: data
  });
} //查询我的小程序列表

export function getMiniList() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/list?type=pc",
    method: 'get'
  });
} //查询小程序详情信息

export function getMiniMessage(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/".concat(id, "/detail"),
    method: 'get'
  });
} //验证小程序昵称是否合法de

export function verifyMiniName(appid, nickName) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/verify-nickname',
    method: 'post',
    data: {
      appid: appid,
      nickName: nickName
    }
  });
} //修改小程序昵称

export function setMiniName(appid, nickName, license) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/set-nickname',
    method: 'post',
    data: {
      appid: appid,
      nickName: nickName,
      license: license
    }
  });
} //修改小程序头像

export function setHeadImage(appid, mediaId) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/modify-head-image',
    method: 'post',
    data: {
      appid: appid,
      mediaId: mediaId
    }
  });
} //修改小程序功能介绍

export function setIntroduce(appid, appSignature) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/modify-signature',
    method: 'post',
    data: {
      appid: appid,
      appSignature: appSignature
    }
  });
} //获取授权的url

export function getAuthUrl(templateType) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-auth-url?templateType=".concat(templateType),
    method: 'get'
  });
} //发布审核小程序包

export function releaseMini(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/release?appid=".concat(appid),
    method: 'post'
  });
} // 上传图片到微信

export function updatedImage(appId, type, formData) {
  return request.post("/p1/api/v1/wx/third-party/weapp/media-upload?appid=".concat(appId, "&type=").concat(type), formData);
} // 体验者列表读取

export function loadExpListApi(appId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/tester/list?appid=".concat(appId),
    method: 'get'
  });
} // 绑定体验者

export function bindExpUserApi(appId, weixin) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/bind-tester",
    method: 'post',
    data: {
      wechatid: weixin,
      appid: appId
    }
  });
} // 解除绑定体验者

export function unbindExpUserApi(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/unbind-tester?id=".concat(id),
    method: 'delete'
  });
} // 读取功能设置列表

export function loadOptionApi(appId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/products/settings?appid=".concat(appId),
    method: 'get'
  });
} // 提交功能设置列表

export function setOptionApi(obj) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/products/settings",
    method: 'put',
    data: obj
  });
} // 根据appid查询小程序的代码数据

export function getCodeDetail(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code-detail?appid=".concat(appid),
    method: 'get'
  });
} // 根据appId上传最新代码

export function codeCommit(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code-commit?appid=".concat(appid),
    method: 'post'
  });
} // 根据appId提交审核

export function submitAudit(appid, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/submit-audit?appid=".concat(appid),
    method: 'post',
    data: data
  });
} // 根据appId撤销审核

export function cancelAudit(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code/cancel-audit?appid=".concat(appid),
    method: 'put'
  });
} // 全网发布（上传代码+提交审核）

export function allCommit() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code/all-commit",
    method: 'post'
  });
} // 查询全网发布的记录

export function allCommitLog(page, size) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code/all-commit-log?page=".concat(page, "&size=").concat(size),
    method: 'get'
  });
} // 查询全网发布会受影响的小程序个数

export function allCommitNum() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code/all-commit/affect-detail",
    method: 'get'
  });
} // 查询某个全网发布记录的详情（根据模板id，查询所有的模板id的小程序审核状态）

export function allCommitLogOne(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/code/all-commit-log/detail?id=".concat(id),
    method: 'get'
  });
} // 查询小程序的微信认证状态（用于小程序绑定手机号时，判断小程序是否已认证）

export function inquireState(appid) {
  return request({
    url: "/p1/api/v1/wx/weapp/verify-type?appid=".concat(appid),
    method: 'get'
  });
} // 查询绑定的详情

export function inquireInfo(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mch-info?appid=".concat(appid),
    method: 'get'
  });
} // 绑定商户

export function bindingUser(appid, mchId, mchApiKey) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/bind-mch-info",
    method: 'post',
    data: {
      appid: appid,
      mchId: mchId,
      mchApiKey: mchApiKey
    }
  });
} // 解绑商户

export function unbindgUser(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/unbind-mch-info?appid=".concat(appid),
    method: 'delete'
  });
} // 获取支付数据（测试支付功能）

export function mchPayTest(appid) {
  return request({
    url: "/p1/api/v1/wx/weapp/mch-pay-test?appid=".concat(appid),
    method: 'get'
  });
} // 35.查询支付测试的小程序码

export function getQrcode(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/test-pay/qrcode?appid=".concat(appid),
    method: 'get'
  });
} // 42.获取可以设置的所有类目

export function getAllCategories(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-all-categories?appid=".concat(appid),
    method: 'get'
  });
} // 43.查询小程序已设置的类目列表

export function getCategory(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-category?appid=".concat(appid),
    method: 'get'
  });
} // 44.添加类目

export function addCategory(appid, first, second, certicates) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/add-category?appid=".concat(appid),
    method: 'post',
    data: {
      first: first,
      second: second,
      certicates: certicates
    }
  });
} // 45.删除类目

export function deleteCategory(appid, first, second) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/delete-categories?appid=".concat(appid, "&first=").concat(first, "&second=").concat(second),
    method: 'delete',
    data: {
      first: first,
      second: second
    }
  });
} // 46.修改审核未通过的类目

export function modifyCategory(appid, first, second, certicates) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/modify-categories?appid=".concat(appid),
    method: 'put',
    data: {
      first: first,
      second: second,
      certicates: certicates
    }
  });
} //53.wx-CMS查询单个小程序对账单

export function getBillList(appid, page, size) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/bill?appid=".concat(appid, "&page=").concat(page, "&size=").concat(size),
    method: 'post'
  });
} //54.wx-CMS导出单个小程序对账单

export function downloadBill(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/bill/export?appid=".concat(appid),
    method: 'post',
    responseType: 'blob'
  });
} //1.新增小程序一级分类

export function addFirstClass(name, parentId, appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class",
    method: 'post',
    data: {
      name: name,
      parentId: parentId,
      appid: appid
    }
  });
} //2.修改小程序一级分类名称

export function setFirstClass(id, name) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class",
    method: 'put',
    data: {
      id: id,
      name: name
    }
  });
} //3.查询小程序一级/二级分类列表(单次查询一级列表或者二级列表)

export function getClass(appid, parentId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/list?appid=".concat(appid, "&parentId=").concat(parentId),
    method: 'get'
  });
} //4.查询小程序一级/二级分类列表(一二级分类都返回)

export function getAllClass(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/all-list?appid=".concat(appid),
    method: 'get'
  });
} //5.删除小程序一级/二级分类

export function deleteClass(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class?id=".concat(id),
    method: 'delete'
  });
} //6.小程序一级/二级分类排序

export function sortClass(appid, parentId, classList) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/sort",
    method: 'put',
    data: {
      appid: appid,
      parentId: parentId,
      classList: classList
    }
  });
} //1.获取七牛上传文件的token

export function getUploadToken(configId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/upload-token?configId=".concat(configId),
    method: 'get'
  });
}
export function getUploadFileToken(nameSuffix) {
  return request({
    url: "/p2/api/v2/upload/token?nameSuffix=".concat(nameSuffix),
    method: 'get'
  });
} //2.保存上传的文件

export function addFile(appid, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/add-file?appid=".concat(appid),
    method: 'post',
    data: data
  });
} //3.查询文件列表（分页）

export function getFileList(appid, page) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/file/list?appid=".concat(appid, "&page=").concat(page),
    method: 'get'
  });
} //4.查询小程序使用的空间情况

export function getUsageAmount(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/file/usage-amount?appid=".concat(appid),
    method: 'get'
  });
} //5.删除文件

export function deleteFile(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/delele-file?id=".concat(id),
    method: 'delete'
  });
} //查询小程序是否需要交费、交费设置

export function miniNeedPay(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/need-pay?appid=".concat(appid),
    method: 'get'
  });
} //生成微信支付订单

export function weixinPayOrder(appid, priceId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/wx-pay/order?priceId=".concat(priceId, "&appid=").concat(appid),
    method: 'get'
  });
} //创建线上中心应用 生成微信支付订单

export function createWxmsWeixinPayOrder(appName, priceId) {
  var data = {
    agencyPriceId: priceId,
    appName: appName
  };

  if (sessionStorage.getItem('platformType')) {
    data.virtualPlatformKey = sessionStorage.getItem('platformType');
  }

  return request({
    url: "/p1/api/v1/weapp/apply/create/wx-pay/order",
    method: 'post',
    data: data
  });
} //生成微信支付订单

export function weixinPayH5Order(appid, month) {
  return request({
    url: "/p1/api/v1/websit/wx-pay/order?month=".concat(month, "&appid=").concat(appid),
    method: 'get'
  });
} //生成支付宝支付订单

export function aliPayOrder(appid, priceId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/alipay/order?priceId=".concat(priceId, "&appid=").concat(appid),
    method: 'get'
  });
} //创建线上中心应用 生成支付宝支付订单

export function createWxmsAliPayOrder(appName, priceId) {
  var data = {
    agencyPriceId: priceId,
    appName: appName
  };

  if (sessionStorage.getItem('platformType')) {
    data.virtualPlatformKey = sessionStorage.getItem('platformType');
  }

  return request({
    url: "/p1/api/v1/weapp/apply/create/alipay/order",
    method: 'post',
    data: data
  });
} //生成支付宝支付订单

export function aliPayH5Order(appid, month) {
  return request({
    url: "/p1/api/v1/website/alipay/order?month=".concat(month, "&appid=").concat(appid),
    method: 'get'
  });
} //根据订单id，查询订单支付状态

export function orderPayStatus(orderId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/orders/payStatus?orderId=".concat(orderId),
    method: 'get'
  });
}
/**
 * 使用邀请码支付
 * @param {String} coId
 * @param {String} code
 */

export function invitPay(coId, code) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/invite-code-pay",
    method: 'post',
    data: {
      code: code
    },
    headers: {
      coId: coId
    }
  });
}
/**
 * 创建线上中心应用 使用邀请码支付
 */

export function createWxmsInvitPay(appName, code) {
  var data = {
    appName: appName,
    code: code
  };

  if (sessionStorage.getItem('platformType')) {
    data.virtualPlatformKey = sessionStorage.getItem('platformType');
  }

  return request({
    url: "/p1/api/v1/weapp/apply/create/invite-code-pay",
    method: 'post',
    data: data
  });
} //10.查询小程序的基本信息

export function getBasicInfo(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/basic-info?appid=".concat(appid),
    method: 'get'
  });
} //2.小程序预审申请列表

export function getAuditApply(page) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/audit-apply/list?page=".concat(page),
    method: 'get'
  });
} //3.审核小程序的预审申请

export function auditApply(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/audit-apply/audit",
    method: 'put',
    data: data
  });
} //2.申请免费试用时，发送短信验证码

export function getMiniCode(data) {
  return request({
    url: "/p1/api/v1/sms/captcha/weapp-trial-apply",
    method: 'post',
    data: data
  });
} //3.申请免费试用小程序

export function probationMini(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/trial-apply",
    method: 'post',
    data: data
  });
} //4.安卓下载APP的地址

export function appVersions(data) {
  return request({
    url: "/p2/api/v1/app-versions/check",
    method: 'post',
    data: data
  });
}
/**
 * 获取用户权限
 */

export function getUType(token, appid) {
  return request({
    url: "/p2/api/v1/weapp/manager/user/roleId",
    method: 'get'
  });
}
/**
 * 查询小程序设计的信息
 */

export function getMiniConfig(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/config?appid=".concat(appid),
    method: 'get'
  });
}
/**
 * 查询小程序设计的信息
 */

export function addTopImage(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/add-top-image",
    method: 'post',
    data: data
  });
}
export function getNoticeNum() {
  return request({
    url: "/p2/api/v2/wx/third-party/weapp/notice/unread-num",
    method: 'get'
  });
}
export function loadNoticeList(page, size) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/notice/list?page=".concat(page, "&size=").concat(size),
    method: 'get'
  });
}
/**
 * 读取我的文章列表
 */

export function loadMyArticle(pageNum, keyword, searchType) {
  var obj = {
    page: pageNum,
    searchOwnArticle: searchType == 1 || searchType == 2 || searchType == 3 ? 0 : 1
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  var url = searchType == 1 || searchType == 4 ? "/p2/api/v1/weapp/belong/article/public/search?page=".concat(pageNum) : searchType == 2 || searchType == 5 ? "/p2/api/v1/weapp/authed/article/search?page=".concat(pageNum) : "/p2/api/v1/weapp/belong/article/secret/search?page=".concat(pageNum);
  return request({
    url: url,
    method: 'post',
    data: obj
  }); // return request({
  //   url: `/p2/api/v2/persons/${userId}/articles?page=${pageNum}`,
  //   method: 'get'
  // })
} // 获取文章列表每个筛选项的数据总数

export function getListTotal() {
  return request({
    url: "/p2/api/v1/weapp/belong/article/count",
    method: "GET"
  });
} // 获取活动列表每个筛选项的数据总数

export function getActivityListTotal() {
  return request({
    url: "/p2/api/v1/weapp/manager/activity/count",
    method: "GET"
  });
} // 获取云协作列表每个筛选项的数据总数

export function getProjectListTotal() {
  return request({
    url: "/p2/api/v1/weapp/manager/project/count",
    method: "GET"
  });
} // 获取圈子列表每个筛选项的数据总数

export function getCircleListTotal() {
  return request({
    url: "/p2/api/v1/weapp/manager/circle/count",
    method: "GET"
  });
}
/**
 * 读取我的文章列表
 */

export function loadMyArticleO(pageNum, keyword) {
  var obj = {
    page: pageNum
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  return request({
    url: "/p2/api/v1/my-articles/search?page=".concat(pageNum),
    method: 'post',
    data: obj
  }); // return request({
  //   url: `/p2/api/v2/persons/${userId}/articles?page=${pageNum}`,
  //   method: 'get'
  // })
}
/**
 * 判断文章是否已评分（评分不可编辑）
 */

export function articleCanEdit(artId) {
  return request({
    url: "/p2/api/v1/articles/edit/".concat(artId),
    method: 'get'
  });
}
/**
 * 删除文章，活动，云协作、公众号素材、圈子、问卷
 */

export function listDel(id, type) {
  return request({
    url: "/p2/api/v1/mini-program/co/manager/unbind-auth-content",
    method: 'PUT',
    data: {
      itemId: id,
      itemType: type
    }
  });
}
export function delArticle(id) {
  return request({
    url: "/p2/api/v1/articles/".concat(id),
    method: 'DELETE'
  });
}
export function optionArticle(id, isSecreted) {
  return request({
    url: "/p2/api/v1/articles/change-nature/".concat(id, "?isSecreted=").concat(isSecreted),
    method: 'PUT'
  });
}
/**
 * 读取我的管理活动列表
 */

export function loadMyActivity(pageNum, keyword, searchType) {
  var obj = {
    page: pageNum,
    searchType: searchType
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  return request({
    url: "/p2/api/v1/weapp/manager/activity/list?page=".concat(pageNum),
    method: 'post',
    data: obj
  });
}
/**
 * 读取我的管理活动列表
 */

export function loadMyActivityO(userId, pageNum, keyword) {
  return request({
    url: "/p2/api/v1/myActiInfos?page=".concat(pageNum),
    method: 'post',
    data: {
      actName: keyword
    }
  });
}
/**
 * 判断用户是否实名认证
 */

export function confirmRealAuth() {
  return request({
    url: "/p2/api/v1/myActiCertification",
    method: 'get'
  });
}
/**
 * 读取我的管理群列表
 */

export function loadMyProject(pageNum, keyword, searchType) {
  var obj = {
    page: pageNum,
    searchType: searchType
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  return request({
    url: "/p2/api/v1/weapp/manager/project/list?page=".concat(pageNum),
    method: 'post',
    data: obj
  }); // return request({
  //   url: `/p2/api/v2/persons/${userId}/managements/projects?page=${pageNum}`,
  //   method: 'get'
  // })
}
/**
 * 读取我的管理群列表
 */

export function loadMyProjectO(pageNum, keyword) {
  var obj = {
    page: pageNum
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  return request({
    url: "/p2/api/v1/my-manage-project/search?page=".concat(pageNum),
    method: 'post',
    data: obj
  });
}
/**
 * 删除群
 */

export function projectDel(proId) {
  return request({
    url: "/p2/api/v1/projects/delete/".concat(proId),
    method: 'put',
    data: null
  });
}
/**
 * 读取公众号列表
 */

export function loadPubList(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp-list?appid=".concat(appid),
    method: 'get'
  });
}
/**
 * 读取公众号文章列表
 * @param {String} id 公众号列表返回的id
 */

export function loadPubArticleList(id, page, keyword) {
  var url = "/p1/api/v1/wx/third-party/weapp/mp/".concat(id, "/articles?page=").concat(page);
  var key = "";

  if (keyword && keyword != "") {
    key = keyword;
  }

  return request({
    url: url,
    method: 'post',
    data: {
      keywords: key
    }
  });
}
/**
 * 点击更新同步公众号文章列表
 * @param {String} id 公众号列表返回的id
 */

export function updataPubArticleList(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/".concat(id, "/sync"),
    method: 'put',
    data: {
      id: id
    }
  });
}
/**
 * 查询公众号同步进度信息
 * @param {Object} id
 */

export function updataCheck(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/".concat(id, "/sync-progress"),
    method: 'get'
  });
}
/**
 * 编辑公众号文章
 * @param {String} id 公众号文章id
 * @param {String} title 公众号文章标题
 * @param {String} titleImg 公众号文章标题图
 * @param {String} digest 公众号文章摘要
 */

export function updataPubArticle(obj) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/article/".concat(obj.id),
    method: 'put',
    data: obj
  });
}
/**
 * 获取添加微信公众号的链接
 * @param {Object} appid
 */

export function getAddWeixinPubUrl(appid, type) {
  var busiType = '';

  if (type) {
    busiType = type;
  } else {
    busiType = 1;
  }

  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/get-auth-url?appid=".concat(appid, "&busiType=").concat(busiType),
    method: 'get'
  });
}
/**
 * 删除公众号文章
 */

export function delPubArticle(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/article/".concat(id),
    method: 'delete'
  });
}
/**
 * 删除小程序
 */

export function deleteMini(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp?appid=".concat(appid),
    method: 'delete'
  });
}
/**
 * 获取已授权列表
 * @param {Object} loadType 读取的列表类型 1：已授权 0：未授权
 * @param {Object} pageNum
 */

export function getPermissionsList(loadType, pageNum, itemType) {
  return request({
    url: "/p2/api/v1/mini-program/co/manager/auth-apply?auditStatus=".concat(loadType, "&page=").concat(pageNum, "&itemType=").concat(itemType),
    method: 'get'
  });
}
/**
 * 搜索待授权列表的总数（2021-2-9 弃用)
 * @param {Object} keyWord
 */
// export function searchPermissionsCount(keyWord) {
//   let obj={
//     keywords: keyWord
//   }
//   return request({
//     url: `/p2/api/v1/mini-program/co/search/count`,
//     method: 'post',
//     data: obj
//   })
// }

/**
 * 搜索待授权文章列表
 * @param {Object} keyWord
 */

export function searchPermissionsArticle(page, keyWord) {
  var obj = {
    keywords: keyWord
  };
  return request({
    url: "/p2/api/v1/weapp/manage/auth-apply/article/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
/**
 * 搜索待授权群列表
 * @param {Object} keyWord
 */

export function searchPermissionsProject(page, keyWord) {
  var obj = {
    keywords: keyWord
  };
  return request({
    url: "/p2/api/v1/weapp/manage/auth-apply/project/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
/**
 * 搜索待授权圈子列表
 * @param {Object} keyWord
 */

export function searchPermissionsCircle(page, keyWord) {
  var obj = {
    keywords: keyWord
  };
  return request({
    url: "/p2/api/v1/weapp/manage/auth-apply/circle/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
/**
 * 搜索待授权圈子列表
 * @param {Object} keyWord
 */

export function searchPermissionsSearch(page, keyWord, appid) {
  var obj = {
    keywords: keyWord,
    appid: appid
  };
  return request({
    url: "/p1/api/v1/weapp/manage/auth-apply/survey/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
/**
 * 搜索待授权活动列表
 * @param {Object} keyWord
 */

export function searchPermissionsActivity(page, keyWord) {
  var obj = {
    keywords: keyWord
  };
  return request({
    url: "/p2/api/v1/weapp/manage/auth-apply/activity/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
/**
 * 查询小程序的快速注册填写的信息
 * @param {Object} appid
 */

export function getCreateInfo(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/create-info?appid=".concat(appid),
    method: 'get'
  });
}
/**
 * 申请授权
 */

export function addAuthorizeApi(itemId, itemType, applyReason) {
  var obj = {
    itemId: itemId,
    itemType: itemType,
    applyReason: applyReason
  };
  return request({
    url: "/p2/api/v1/mini-program/co/manager/auth-apply",
    method: 'post',
    data: obj
  });
} //申请内容授权时，判断内容是否需要审核才能用过

export function isNeedAudit(itemId, itemType) {
  var obj = {
    itemId: itemId,
    itemType: itemType
  };
  return request({
    url: "/p2/api/v1/mini-program/co/manager/auth-apply/is-need-audit",
    method: 'post',
    data: obj
  });
}
/**
 * 解绑授权
 */

export function delAuthorizeApi(itemId, itemType) {
  var obj = {
    itemId: itemId,
    itemType: itemType
  };
  return request({
    url: "/p2/api/v1/mini-program/co/manager/unbind-auth-content",
    method: 'put',
    data: obj
  });
}
/**
 * 审批授权
 * @param {Object} itemId
 * @param {Object} status 1:同意 2:拒绝
 */

export function dealAuthorizeApi(itemId, status) {
  var obj = {
    id: itemId,
    auditStatus: status
  };
  return request({
    url: "/p2/api/v1/todo/auth-audit",
    method: 'post',
    data: obj
  });
} //帮助中心导航配置

export function getHelpCenter() {
  return request({
    url: "/p2/api/v1/help-center/navs",
    method: 'get'
  });
}
/*
 * 微信敏感词校验
 */

export function checkSensitive(text) {
  return request({
    url: "/p2/api/v1/weapp/check-sensitive",
    method: 'post',
    data: {
      comment: text
    }
  });
}
/*
 * 查询小程序是否可以注册试用版小程序
 */

export function canRegisterBeta(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/can-register-beta?appid=".concat(appid),
    method: 'get'
  });
}
/*
 * 查询小程序名字
 */

export function getNickName(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/nick_name?appid=".concat(appid),
    method: 'get'
  });
}
/*
 * 创建试用版小程序
 */

export function cerateTrialMini(data) {
  return request({
    url: "/p1/api/v1/third-party/weapp/register-beta",
    method: 'post',
    data: data
  });
}
/*
 * 判断试用版小程序是否注册成功
 */

export function betaRegisterStatus(id) {
  return request({
    url: "/p1/api/v1/third-party/weapp/beta-register-status?appid=".concat(id),
    method: 'get'
  });
}
/*
 * 查询所有小程序皮肤列表
 */

export function getMiniSkin(id) {
  // return request({
  //     url: `/p1/api/v1/wx/third-party/weapp/skin/list?classId=${appid}`,
  //     method: 'get',
  // })
  // 2024.5.21改动
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/in-used/skin?id=".concat(id),
    method: 'GET'
  });
}
/*
 * 小程序设置皮肤
 */

export function setMiniSkin(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/set-skin",
    method: 'put',
    data: data
  });
}
/**
 * 小程序导航显示/隐藏
 */

export function showNav(id, type) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/isShow",
    method: "PUT",
    data: {
      id: id,
      isShow: type ? 0 : 1
    }
  });
}
/**
 * 添加删除置顶图
 */

export function stickImg(topImage) {
  return request({
    url: "/p1/api/v1/weapp/class/school-mgr/set-top-image",
    method: "PUT",
    data: {
      topImage: topImage
    }
  });
}
/**
 * 查询置顶图片
 */

export function checkStickImage() {
  return request({
    url: "/p1/api/v1/weapp/class/school-mgr/top-image",
    method: "GET"
  });
}
/**
 * 判断输入的手机号是否需要验证码
 * @param {Object} data
 */

export function checkNeedCaptcha(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/phone/judge-need-captcha',
    method: 'post',
    data: data
  });
}
/**
 * 发送设置联系方式的短信验证码
 * @param {Object} data
 */

export function sendCaptcha(data) {
  return request({
    url: '/p1/api/v1/sms/captcha/weapp-bind-phone',
    method: 'post',
    data: data
  });
}
/**
 * 设置小程序的联系人电话号码
 * @param {Object} data
 */

export function sendMiniPhone(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/phone',
    method: 'post',
    data: data
  });
}
/**
 * 用户提交小程序版本升级申请
 * @param {Object} data
 */

export function subUpgradeApply(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/code-upgrade-apply',
    method: 'post',
    data: data
  });
}
/**
 * 同步小程序信息
 * @param {Object} data
 */

export function refreshInfo(appid) {
  return request({
    url: "/p1/api/v1/wx/refresh-info?appid=".concat(appid),
    method: 'post',
    data: {
      appid: appid
    }
  });
}
/**
 * 获取微信公众平台换绑页面的url
 * @param {Object} data
 */

export function getMiniPlatformUrl(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/change-wx-manager/get-url?appid=".concat(appid),
    method: 'get'
  });
}
/**
 * 根据appid，获取授权公众号的授权页面地址
 * @param {Object} data
 */

export function getMiniAuthorizationUrl(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/register-weapp-by-mp/get-mp-appid-url?appid=".concat(appid),
    method: 'get'
  });
}
/**
 * 获取复用公众号注册小程序的授权页面地址
 * @param {Object} data
 */

export function getMiniAuthorizationUrl2(appid, mpAppid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/register-weapp-by-mp/get-register-weapp-url?appid=".concat(appid, "&mpAppid=").concat(mpAppid),
    method: 'get'
  });
}
/**
 * 查询当前小程序是否支持圈子、问卷
 * @param {Object} data
 */

export function versionSupport() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/version-support",
    method: 'get'
  });
}
/**
 * 查询小程序的代码版本详情
 * @param {Object} data
 */
// export function getMiniVersion(appid) {
//   return request({
//       url: `/p1/api/v1/wx/third-party/weapp/${appid}/code-detail`,
//       method: 'get'
//   })
// }

/**
 * 查询小程序弹框内容
 * @param {Object} data
 */

export function getFailPop(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-fail-pop?appid=".concat(appid),
    method: 'get'
  });
} // 获取轮播图

export function getAllSwiperImg(homeId) {
  return request({
    url: "/p1/api/v2/wx/third-party/weapp/banner/list?homeId=".concat(homeId),
    method: "GET"
  });
} // 保存轮播图

export function saveSwiper(obj) {
  return request({
    url: "/p1/api/v2/wx/third-party/weapp/banner",
    method: "POST",
    data: obj
  });
} // 保存导航图

export function saveNavIcon(obj) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/icon",
    method: "PUT",
    data: obj
  });
} // 保存布局类型

export function saveContList(obj) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/layout-type",
    method: "PUT",
    data: obj
  });
} // 查询用户在该小程序的成员的状态

export function getUserStatus(coId) {
  return request({
    url: "/p2/api/v1/weapp/manager/user/member-status",
    coidAppid: coId,
    method: "GET"
  });
} //读取标题图列表

export function loadTopImgList() {
  return request({
    url: "/p2/api/v1/mobile/activities/type-images",
    method: "Get"
  });
} //获取素材库文章列表

export function loadSuCaiKuList(page, keyword) {
  return request({
    url: "/p2/api/v1/my-articles/search?page=".concat(page),
    method: "post",
    data: {
      authStatus: 0,
      keywords: keyword || ""
    }
  });
} //获取素材库活动列表

export function loadSuCaiKuActivityList(page, keyword) {
  return request({
    url: "/p2/api/v1/myActiInfos?page=".concat(page),
    method: "post",
    data: {
      authStatus: 0,
      actName: keyword || ""
    }
  });
} //获取素材库云协作列表

export function loadSuCaiKuProjectList(page, keyword) {
  return request({
    url: "/p2/api/v1/my-manage-project/search?page=".concat(page),
    method: "post",
    data: {
      authStatus: 0,
      keywords: keyword || ""
    }
  });
} //查看预约列表

export var getSubscribeList = function getSubscribeList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/manager/reservation/search?page=".concat(page),
    method: "post",
    data: data
  });
}; //查看微页面列表

export var getMicroList = function getMicroList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-page/search?page=".concat(page),
    method: "post",
    data: data
  });
}; //获取素材库圈子列表

export function loadSuCaiKuCircleList(page, keyword) {
  return request({
    url: "/p2/api/v1/my-circle/search?page=".concat(page),
    method: "post",
    data: {
      authStatus: 0,
      keywords: keyword || ""
    }
  });
} //获取素材库问卷列表

export function loadSuCaiKuWenList(page, keyword) {
  return request({
    url: "/p1/api/v1/my-survey/search?page=".concat(page),
    method: "post",
    data: {
      authStatus: 0,
      keywords: keyword || ""
    }
  });
} // 添加到素材库

export function addSuCaiKu(id, type) {
  return request({
    url: "/p2/api/v1/weapp/user/auth-data",
    method: "post",
    data: {
      itemId: id,
      itemType: type
    }
  });
}
/**
 * 删除文章
 */

export function articleDel(artId) {
  return request({
    url: "/p2/api/v1/articles/".concat(artId),
    method: 'delete'
  });
}
export function addAuthData(obj) {
  return request({
    url: "/p2/api/v1/weapp/user/batch-auth-data",
    method: "POST",
    data: {
      itemList: obj
    }
  });
}
/**
 * 判断是否能创建移动端
 */

export function canCreateH5() {
  return request({
    url: "/p1/api/v1/website/can-create",
    method: 'get'
  });
}
/**
 * 创建移动网站
 * @param {Object} data
 */

export function createH5(data) {
  return request({
    url: "/p1/api/v1/website",
    method: 'post',
    data: data
  });
}
/**
 * 查询我的移动网站列表
 */

export function loadH5List() {
  return request({
    url: "/p1/api/v1/user/website-list",
    method: 'get'
  });
}
/**
 * 查询我的移动网站的支付价格
 * @param {String} appId
 */

export function payH5Info(appId) {
  return request({
    url: "/p1/api/v1/website/".concat(appId, "/product-price-list"),
    method: 'get'
  });
}
/**
 * 根据邀请码支付移动网站
 * @param {Object} data
 */

export function payH5Code(coId, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/invite-code-pay",
    method: 'post',
    data: data,
    headers: {
      coId: coId
    }
  });
}
/**
 * 查询我的移动网站详情
 * @param {String} appId
 */

export function loadH5Info(appId) {
  return request({
    url: "/p1/api/v1/website/".concat(appId, "/detail"),
    method: 'get'
  });
}
/**
 * 修改移动网站的名称、头像
 * @param {Object} data
 */

export function editH5Info(data) {
  return request({
    url: "/p1/api/v1/website/nickname-and-img?ver=2",
    method: 'put',
    data: data
  });
}
/**
 * 确认小程序的“提示管理者设置手机号”的弹框
 */

export function clearManagerTelPop() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/fail-pop/confirm-manager-phone-tips",
    method: 'put'
  });
}
/**
 * 读取系统消息列表
 * @param {Number} page
 */

export function loadSysMessageList(page) {
  return request({
    url: "/p2/api/v1/notify-notices?page=".concat(page),
    method: 'get'
  });
}
/**********H5收款设置*********************** */

export function getH5Status() {
  return request({
    url: '/p1/api/v1/website/mch-info',
    method: 'get'
  });
}
export function unBindH5() {
  return request({
    url: '/p1/api/v1/website/mch-info',
    method: 'delete'
  });
}
export function BindH5(data) {
  return request({
    url: '/p1/api/v1/website/mch-info',
    method: 'put',
    data: data
  });
}
/******获取左侧菜单全部的权限**************** */

export function getAllRole(token, id) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/left-menu-data',
    method: 'GET',
    data: {
      token: token
    }
  });
}
/**
 * 是否可创建活动
 */

export function canCreateActivity() {
  return request({
    url: '/p2/api/v1/activity/create-max-limit',
    method: 'GET'
  });
} // 获取集团小程序列表

export function getGroupList() {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/group-and-org/list',
    method: 'GET'
  });
} // 获取活动投稿分类

export function getTabActiveSort(id) {
  return request({
    url: "/p2/api/v1/acti-entry/".concat(id, "/group-options?_=") + new Date() * 1,
    method: 'get'
  });
} // 获取活动投稿

export function getTabActiveList(data, page) {
  return request({
    url: '/p2/api/v5/mobile/activities/rank?page=' + page,
    method: 'post',
    data: data
  });
} // 获取活动投稿

export function getActiveList(data, page) {
  return request({
    url: '/p2/api/v1/weapp/manager/created-activities/list?page=' + page,
    method: 'post',
    data: data
  });
} // 获取应用列表

export function getApplyAllList(key) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/list?key=".concat(key || ''),
    method: 'get'
  });
} // 创建应用

export function createApply(data, key) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply",
    method: 'post',
    data: {
      appName: data,
      key: key
    }
  });
} // 删除应用

export function deleteApply(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/".concat(id),
    method: 'delete'
  });
} // 应用重命名

export function reNameApply(id, name) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply",
    method: 'put',
    data: {
      id: id,
      appName: name
    }
  });
} // 获取应用详情

export function getApplyList(id) {
  var url = "";

  if (sessionStorage.getItem("group") == 'true') {
    url = "/p1/api/v1/weapp/manager/apply/group/".concat(id, "/detail");
  } else {
    url = "/p1/api/v1/weapp/manager/apply/".concat(id, "/detail");
  }

  return request({
    url: url,
    method: 'get'
  });
} // 获取应用内容概况

export function getApplyCountFn(id) {
  var url = "";

  if (sessionStorage.getItem("group") == 'true') {
    url = "/p1/api/v1/weapp/manager/apply/group/".concat(id, "/conent-usage");
  } else {
    url = "/p1/api/v1/weapp/manager/apply/".concat(id, "/conent-usage");
  }

  return request({
    url: url,
    method: 'get'
  });
} // 获取版本服务价格表

export function getServerPrice(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/".concat(id, "/service-price"),
    method: 'get'
  });
}
/**
 * 查询是否超出内容创建数限制
 * @param {String} id
 * @param {Number} type (1：文章 2：活动 3：云协作 4：圈子 5：问卷 6：商品 7：文集 8：相册 9：专栏 10：公众号)
 */

export function getLimit(id, type) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/content-limit?id=".concat(id, "&type=").concat(type),
    method: 'get'
  });
} //是否完成主体认证

export function isSubject(id) {
  return request({
    url: '/p1/api/v1/weapp/manager/apply/subject-status',
    method: "GET",
    data: {
      id: id
    }
  });
} //发布网站

export var publishWebsite = function publishWebsite() {
  return request({
    url: '/p1/api/v1/website/publish',
    method: "PUT"
  });
}; //上传身份证、营业执照等私密文件

export var handleUploadFile = function handleUploadFile(file) {
  return request.post("/p1/api/v1/weapp/manager/apply/subject-info/file-upload", file).then(function (res) {
    return res;
  }, function (err) {
    return err;
  });
}; //查询线上中心的主体认证信息

export var checkApproveSubjectMsg = function checkApproveSubjectMsg() {
  return request({
    url: '/p1/api/v1/weapp/manager/apply/subject-info/detail',
    method: "GET"
  });
}; //保存/变更主体认证信息

export var saveApproveSubjectMsg = function saveApproveSubjectMsg(data) {
  return request({
    url: '/p1/api/v1/weapp/manager/apply/subject-info',
    method: "POST",
    data: data
  });
}; // 基本信息

export var checkBasicInfo = function checkBasicInfo(appid) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/".concat(appid, "/basic-detail"),
    method: "GET"
  });
}; // 保存客服设置

export var saveServerMsg = function saveServerMsg(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/customer-info",
    method: "PUT",
    data: data
  });
}; //是否配置了自定义域名

export var domainEnabled = function domainEnabled(appid) {
  return request({
    url: "/p1/api/v1/website/".concat(appid, "/domain-enabled"),
    method: "GET"
  });
}; //根据homeId查询背景音乐设置

export var getBgMusic = function getBgMusic(homeId) {
  return request({
    url: "/p1/api/v1/weapp/manager/home/music-setting?id=".concat(homeId),
    method: "GET"
  });
}; //根据homeId修改背景音乐设置

export var setBgMusic = function setBgMusic(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/home/music-setting",
    method: "PUT",
    data: data
  });
}; //修改专栏的导航设置

export var updataNavStyle = function updataNavStyle(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/nav-style",
    method: "PUT",
    data: data
  });
}; //判断当前小程序是否为私服

export function isSF() {
  return request({
    url: "/p1/api/v1/is-sf",
    method: 'GET'
  });
} //根据id和type获取小程序分享码

export function getAppletCode(itemId, itemType) {
  return request({
    url: "/p1/api/v1/weapp/item/wxa-code?itemId=".concat(itemId, "&itemType=").concat(itemType),
    hideToastAll: true
  });
} // 长链接转短链接

export function shortLinkUrl(e) {
  return request({
    url: "/p1/api/v1/short-link",
    method: "POST",
    data: {
      url: e
    },
    hideToastAll: true
  });
} // 获取当前用户志愿服务权限

export function volunterRole() {
  return request({
    url: "/p1/api/v1/user/auth",
    method: "get"
  });
}
/**
 * 查询线上中心的是否绑定小程序、是否授权备案权限、是否已备案的状态
 */

export function getMiniFilings() {
  return request({
    url: "/p1/api/v1/weapp/wxa-record/status",
    method: "get"
  });
}
/**
 * 查询通知待办设置
 */

export function getManagerTodoSetting(id, type) {
  return request({
    url: "/p1/api/v1/weapp/manager/todo-setting?itemId=".concat(id, "&itemType=").concat(type),
    method: 'GET'
  });
}
/**
 * 设置通知待办设置
 */

export function putManagerTodoSetting(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/todo-setting/is-open",
    method: 'PUT',
    data: data
  });
}
export function getAuthorizeUrl() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-update-auth-url",
    method: "get"
  });
} // 获取是否绑定过公众号

export function getGZHCode() {
  return request({
    url: "/p1/api/v1/weapp/mp/subcribe-url",
    method: "get"
  });
} // 文章阅读数修改

export function updateReadNum(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/show-article-readnum",
    method: "PUT",
    data: data
  });
} //活动阅读数修改

export function updateActivityReadNum(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/show-activity-readnum",
    method: "PUT",
    data: data
  });
} //圈子动态数修改

export function updateCircleReadNum(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/show-circle-timeline-num",
    method: "PUT",
    data: data
  });
} //圈子成员数修改

export function updateCircleMemberNum(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/show-circle-member-num",
    method: "PUT",
    data: data
  });
} // 修改线上中心的“社交功能”是否开启

export function updateSocial(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/social-open",
    method: "PUT",
    data: data
  });
} // 修改线上中心的“位置信息”是否开启

export function updateLocation(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/location-open",
    method: "PUT",
    data: data
  });
} // 获取分享数据

export function getShareMsg(itemId, itemType) {
  return request({
    url: "/p2/api/v3/weapp/poster-message?itemId=".concat(itemId, "&itemType=").concat(itemType),
    method: "GET"
  });
}
/**
 * 复制某一专栏的数据到某个线上中心下
 */

export function copyWeappClass(data) {
  return request({
    url: "/p1/api/v1/admin/weapp-class/copy",
    method: "POST",
    data: data
  });
} // 查询图文样式下的款式列表

export function getImgTextStyle(type) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/image-text/style-list?layoutType=".concat(type),
    method: "GET"
  });
} // 查询图文样式下的款式列表

export function getImgTextStyleList(styleId) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/image-text/style/info-list?styleId=".concat(styleId),
    method: "GET"
  });
} // 查询微页面详情

export function getMicroDetail(microId) {
  return request({
    url: "/p1/api/v1/micro-pages/".concat(microId),
    method: "GET"
  });
}
export function getStatList(appid) {
  return request({
    url: "/p1/api/v1/weapp/manager/dashboard-page/list?appid=".concat(appid),
    method: "GET"
  });
}
export function addStat(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/dashboard-page",
    method: "POST",
    data: data
  });
}
/**
 * 获取头部和底部的配置
 */

export function loadHeaderAndFooter() {
  return request({
    url: "/p2/api/v1/site-data",
    method: "GET"
  });
}
/**
 * 查询微页面、预约、表单、文集、商品、相册、荣誉证书、荣誉证书模版、抽奖、募捐的数量，包含全部数量和我的数量
 * @param {Number} type 5：问卷 6：商品 7：文集 8：相册 11：荣誉证书 12：成员,13：预约，14：查询，15：抽奖，16：微页面，18：资料中心图片（个数），19：资料中心视频（个数），20：资料中心音频（个数），21：资料中心文档（个数），22：荣誉证书模版，23：募捐
 * @returns 
 */

export function getBelongCount(type) {
  return request({
    url: "/p1/api/v1/weapp/belong/content/count?itemType=".concat(type),
    method: "GET"
  });
}
/**
 * 查询文章、活动、云协作、圈子、表单的已授权、待授权、未授权的数量
 * @param {Number} type 1：云协作，2：文章，3：活动，10：圈子，20：表单
 * @returns 
 */

export function getAuthCount(type) {
  return request({
    url: "/p2/api/v1/mini-program/co/manager/auth-apply/count?itemType=".concat(type),
    method: "GET"
  });
}
/**
 * 根据弹框id查询弹框数据
 * @param {String} id 
 * @returns 
 */

export function getSettingPop(id) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/pop/".concat(id, "/detail"),
    method: "GET"
  });
}
/**
 * 添加弹框数据
 * @returns 
 */

export function setSettingPop(data) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/pop",
    method: "POST",
    data: data
  });
} // 圈子：变更圈主

export function changeCircleMaster(id, data) {
  return request({
    url: "/p2/api/v1/circle/".concat(id, "/change-leader"),
    method: "PUT",
    data: data
  });
} //专栏下的开屏页-清空用户查看次数

export function resetUserViewTimes(id) {
  return request({
    url: "/p1/api/v1/weapp/class/scree/reset-user-view-times",
    method: "PUT",
    data: {
      id: id
    }
  });
} // 获取发布列表

export function getPublishList(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/publish-info/list?page=".concat(data.page),
    method: 'POST',
    data: data
  });
}
/**
 * 查询图片的历史列表
 * @returns 
 */

export function getHistoryRecordsImgList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/image/history/list?page=".concat(page),
    method: 'POST',
    data: data
  });
}
/**
 * 查询图文的历史列表
 * @returns 
 */

export function getHistoryRecordsImgTxtList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/image-text/history/list?page=".concat(page),
    method: 'POST',
    data: data
  });
}
/**
 * 查询内容组件的历史记录
 * @returns 
 */

export function getHistoryRecordsContentList(page, data) {
  return request({
    url: "/p1/api/v1/data-slice/content-module/history/list?page=".concat(page),
    method: 'POST',
    data: data
  });
}
/**
 * 删除图片、图文、内容组件的历史记录
 * @returns 
 */

export function delHistoryRecordsList(id) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/delete-history?id=".concat(id),
    method: 'DELETE'
  });
}
/**
 * 查询内容组件详情页
 * @returns 
 */

export function getAppContentList(id, sceneType) {
  return request({
    url: "/p2/api/v1/data-slice/content-module/detail?id=".concat(id, "&sceneType=").concat(sceneType),
    method: 'GET'
  });
}
/**
 * 查询用户的全部表单、商品的通知待办列表
 * @returns 
 */

export function getTodoAuditList(page, auditStatus) {
  return request({
    url: "/p1/api/v1/wx/third-party/todo-audit/list?page=".concat(page, "&auditStatus=").concat(auditStatus),
    method: 'GET'
  });
} //保存视频号视频

export function saveVideo(data) {
  return request({
    url: "/p1/api/v1/weapp/data-slice/channel-video",
    method: 'POST',
    data: data
  });
} //保存小程序客服设置

export function saveCustomerService(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/apply/mini-customer-info",
    method: 'PUT',
    data: data
  });
}